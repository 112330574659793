<template>
  <div class="main">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>课程管理</BreadcrumbItem>
      <BreadcrumbItem>问卷</BreadcrumbItem>
      <BreadcrumbItem>新建问卷</BreadcrumbItem>
    </Breadcrumb>
    <div style="display: flex">
      <div class="left" style="height: calc(100vh - 140px);overflow: auto">
        <div class="topBtn">题目控件</div>
        <Button long class="btn" @click="addSingle" :disabled="editStatus">
          <Icon type="md-radio-button-on" />
          单选题
        </Button>
        <Button long class="btn" @click="addMulti" :disabled="editStatus">
          <Icon type="md-checkbox" />
          多选题
        </Button>
        <Button long class="btn" @click="addText" :disabled="editStatus">
          <Icon type="md-reorder" />
          文本题
        </Button>
      </div>
      <div class="right">
        <div style="margin-bottom: 10px;display: flex;justify-content: space-between">
          <Input v-model="title" placeholder="请输入问卷名称" style="width: 400px;margin-left: 36px" />
          <div style="float: right">
            <Button style="margin-right: 10px" @click="$router.go(-1)">返回</Button>
            <Button type="primary" @click="save">保存问卷</Button>
          </div>
        </div>
        <div style="height: calc(100vh - 200px);overflow: auto">
          <div v-for="(item, index) in questions" :key="index">
            <div v-if="item.isEdit" class="edit" :id="'anchor-' + index">
              <Form :ref="'question' + index" :label-width="50" :model="item" :rules="ruleValidate">
                <FormItem label="题目" prop="name">
                  <Input v-model="item.name"></Input>
                </FormItem>
                <FormItem
                  :prop="'options.' + i + '.name'"
                  :rules="{
                    required: true,
                    message: '请输入选项内容',
                    trigger: 'blur'
                  }"
                  :label="i === 0 ? '选项' : ''"
                  v-if="item.questionType !== 3"
                  v-for="(op, i) in item.options"
                  :key="i"
                >
                  <div style="display: flex;align-items: center;">
                    <Input v-model="op.name"></Input>
                    <Icon type="md-close" style="font-size: 16px;margin-left: 10px;cursor: pointer" @click="item.options.splice(i, 1)" />
                  </div>
                </FormItem>
                <FormItem v-if="item.questionType !== 3">
                  <Button @click="item.options.push({ name: '' })">新建选项</Button>
                </FormItem>
              </Form>
              <div style="text-align: right">
                <Button
                  @click="
                    !byIconEdit ? questions.splice(index, 1) : (item.isEdit = false)
                    byIconEdit = false
                  "
                  >取消</Button
                >
                <Button type="primary" style="margin-left: 10px" @click="confirmEdit(item, 'question' + index)">确定</Button>
              </div>
            </div>
            <div
              v-else
              class="question"
              @click="
                item.isEdit = true
                byIconEdit = true
              "
              @mouseenter="
                qIndex = index
                isShow = true
              "
              @mouseleave="isShow = false"
            >
              <div style="width: calc(100% - 80px)">
                <h2 style="word-break: break-all;word-break: break-word">
                  {{ index + 1 + '. ' + item.name }}
                </h2>
                <RadioGroup vertical size="large" v-if="item.questionType === 1" style="margin-left: 20px">
                  <Radio :label="op.name" v-for="(op, i) in item.options" :key="i"> </Radio>
                </RadioGroup>
                <CheckboxGroup v-if="item.questionType === 2" style="margin-left: 20px">
                  <Checkbox :label="op.name" v-for="(op, i) in item.options" :key="i" style="display: flex;margin-top: 10px;"> </Checkbox>
                </CheckboxGroup>
                <Input type="textarea" :readonly="true" v-if="item.questionType === 3" style="margin-left: 20px;margin-top: 4px" />
              </div>
              <div class="opR" v-if="isShow && qIndex === index" @click.stop="">
                <div>
                  <Icon
                    type="ios-create"
                    style="cursor: pointer"
                    @click="
                      item.isEdit = true
                      byIconEdit = true
                    "
                  /><br />
                  <Icon type="md-trash" style="cursor: pointer" @click="questions.splice(index, 1)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewQuestionnaire',
  data() {
    return {
      ruleValidate: {
        name: [
          {
            required: true,
            message: '请输入题目',
            trigger: 'blur'
          }
        ]
      },
      title: '',
      byIconEdit: false,
      qIndex: '',
      isShow: false,
      questions: []
    }
  },
  computed: {
    editStatus() {
      let a = false
      this.questions.forEach(item => {
        if (item.isEdit) {
          a = true
        }
      })
      return a
    }
  },
  methods: {
    save() {
      if (this.editStatus) {
        this.$message.error('请完成正在编辑的题目')
        return
      }
      if (this.questions.length === 0) {
        this.$message.error('问卷为空')
        return
      }
      if (this.title === '') {
        this.$message.error('请输入问卷名称')
        return
      }
    },
    confirmEdit(q, name) {
      if (q.questionType !== 3) {
        if (q.options.length < 2) {
          this.$message.error('选项不少于2个')
          return
        }
      }
      this.$refs[name][0].validate(valid => {
        if (valid) {
          q.isEdit = false
        } else {
          this.$message.error('请完成必填项')
        }
      })
    },
    anchor() {
      let val = '#anchor-' + (this.questions.length - 1).toString()
      this.$nextTick(() => {
        this.$el.querySelector(val).scrollIntoView()
      })
    },
    addSingle() {
      this.questions.push({
        questionType: 1,
        name: '',
        options: [{ name: '' }, { name: '' }],
        isEdit: true
      })
      this.anchor()
    },
    addMulti() {
      this.questions.push({
        questionType: 2,
        name: '',
        options: [{ name: '' }, { name: '' }, { name: '' }, { name: '' }],
        isEdit: true
      })
      this.anchor()
    },
    addText() {
      this.questions.push({
        questionType: 3,
        name: '',
        isEdit: true
      })
      this.anchor()
    }
  }
}
</script>

<style scoped lang="less">
.main {
  height: calc(100vh - 70px);
  overflow: auto;
  padding: 10px 20px;
  .custom-breadcrumb {
    margin-bottom: 10px;
  }
  .left {
    padding: 16px 30px;
    width: 200px;
    height: 100%;
    background: rgb(240, 240, 240);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-color: transparent;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    .topBtn {
      width: 140px;
      background: #2d8cf0;
      padding: 8px 20px;
      color: white;
      font-size: 16px;
      border-radius: 4px;
      margin-bottom: 10px;
    }
    .btn {
      margin: 4px 0;
      height: 40px;
    }
  }
  .right {
    padding: 16px 0;
    flex: auto;
    .edit {
      background: rgb(250, 250, 250);
      padding: 16px 36px;
      border: 1px solid rgba(224, 224, 224);
    }
    .question {
      padding: 16px 36px;
      text-align: left;
      cursor: pointer;
      border: 1px solid transparent;
      display: flex;
      justify-content: space-between;
      .opR {
        z-index: 1000;
        cursor: auto;
        min-width: 80px;
        border-left: 1px solid rgba(224, 224, 224);
        font-size: 30px;
        margin: -16px -36px -16px 0;
        background: rgb(240, 240, 240);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:hover {
        background: rgb(250, 250, 250);
        border: 1px solid rgba(224, 224, 224);
      }
    }
  }
}
::v-deep .ivu-checkbox-wrapper {
  font-size: 14px;
}
::v-deep .ivu-checkbox {
  margin-right: 4px;
  margin-top: 2px;
}
::v-deep .ivu-radio-wrapper {
  white-space: normal;
}
::v-deep .ivu-radio-group-vertical .ivu-radio {
  height: 100%;
}
</style>
